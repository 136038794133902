@use '@angular/material' as mat;
@use './m3-theme' as custom-theme;
@include mat.elevation-classes();
@include mat.app-background();

:root {
  @include mat.all-component-themes(custom-theme.$light-theme);
}


@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap');
@tailwind base;
@tailwind components;
@tailwind utilities;

html, body { height: 100%; }
body { margin: 0; font-family: Inter, "Helvetica Neue", sans-serif; }


.app-container {
  @apply container p-4 mx-auto flex flex-col gap-4 h-full;
}

.app-container-padding {
  @apply p-4;
}

.app-header {
  @apply flex justify-between items-center gap-5 p-4 bg-white;
}

.app-table {
  @apply grow m-4 overflow-y-scroll;
}

.line {
  @apply h-[1px] bg-neutral-300 w-full;
}

.line-light {
  @apply h-[1px] bg-neutral-100 w-full;
}

@layer utilities {
  /* Global scrollbar styling */
  ::-webkit-scrollbar {
    width: 5px;
  }

  ::-webkit-scrollbar-track {
    background-color: #f3f4f6;
  }

  ::-webkit-scrollbar-thumb {
    cursor: pointer;
    background-color: #a0aec0;
    border-radius: 100px;
    transition: width 0.2s ease-in-out, background-color 0.2s ease-in-out;
  }

  ::-webkit-scrollbar-thumb:hover {
    background-color: #718096;
    width: 10px;
  }
}
